<template>
  <div>
    <div style="padding-top: 2vh; padding-left: 2vh">
      <a-button-group size="small">
        <a-button
          :type="type === '全部' ? 'primary' : 'default'"
          @click="type = '全部'"
          >全部工单</a-button
        >
        <a-button
          :type="type === '完成' ? 'primary' : 'default'"
          @click="type = '完成'"
          >完成工单</a-button
        >
      </a-button-group>
    </div>

    <div id="facility-error-analysis" style="height: 35vh"></div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { GridComponent, LegendComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([GridComponent, LineChart, CanvasRenderer, LegendComponent]);

export default {
  props: {
    detail: {
      type: Object,
    },
  },

  computed: {
    orderAnalysis() {
      return this.detail.orderAnalysis ? this.detail.orderAnalysis : {};
    },
    total() {
      return Array.isArray(this.orderAnalysis.total)
        ? this.orderAnalysis.total
        : [];
    },
    finished() {
      return Array.isArray(this.orderAnalysis.finished)
        ? this.orderAnalysis.finished
        : [];
    },
  },

  watch: {
    orderAnalysis: {
      handler() {
        this.setChart();
      },
      deep: true,
    },
    type() {
      this.setChart();
    },
  },

  data() {
    return {
      type: "全部",
    };
  },

  mounted() {
    var chartDom = document.getElementById("facility-error-analysis");
    this.chart = echarts.init(chartDom);
    this.setChart();
  },

  beforeDestroy() {
    this.chart?.dispose();
  },

  methods: {
    setChart() {
      if (this.total.length === 0) return;

      this.chart?.clear();

      let list = this.total;

      if (this.type === "完成") {
        list = this.finished;
      }

      var option = {
        color: ["#FFA700", "#0AD8F3"],
        grid: {
          top: "10%",
          right: "5%",
          bottom: "15%",
        },
        legend: {
          textStyle: { color: "#fff" },
          show: false,
          right: "12px",
          top: "12px",
        },
        xAxis: {
          type: "category",
          data: list.map((item) => item.key),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: list.map((item) => item.value),
            type: "line",
            name: this.type === "全部" ? "全部工单" : "完成工单",
          },
        ],
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>