<template>
  <div class="topic">
    <div class="statistic">
      <div class="item">
        <img :src="icon4" alt="" />
        <div>
          <div class="title">全部工单</div>
          <div class="value">
            <span>{{ statistic.total }}</span>
          </div>
        </div>
      </div>
      <div class="item">
        <img :src="icon3" alt="" />
        <div>
          <div class="title">派发工单</div>
          <div class="value">
            <span>{{ statistic.assigned }}</span>
          </div>
        </div>
      </div>
      <div class="item">
        <img :src="icon2" alt="" />
        <div>
          <div class="title">已完成工单</div>
          <div class="value">
            <span>{{ statistic.finished }}</span>
          </div>
        </div>
      </div>
      <div class="item">
        <img :src="icon1" alt="" />
        <div>
          <div class="title">未完成工单</div>
          <div class="value">
            <span style="color: #f00">{{ statistic.unfinished }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper">
      <div class="card">
        <div class="header">
          <div class="title">任务历史分析</div>
          <div class="extra">
            <a-button-group size="small">
              <a-button :type="type === '本月' ? 'primary' : 'default'" @click="type = '本月'">本月</a-button>
              <a-button :type="type === '全年' ? 'primary' : 'default'" @click="type = '全年'">全年</a-button>
            </a-button-group>
          </div>
        </div>

        <FacilityErrorAnalysis :detail="detail" />
      </div>

      <div class="card">
        <div class="header">
          <div class="title">任务来源</div>
          <div class="extra">
            <a-button-group size="small">
              <a-button :type="type === '本月' ? 'primary' : 'default'" @click="type = '本月'">本月</a-button>
              <a-button :type="type === '全年' ? 'primary' : 'default'" @click="type = '全年'">全年</a-button>
            </a-button-group>
          </div>
        </div>
        <FacilityError :detail="detail" />
      </div>

      <div class="card">
        <div class="header">
          <div class="title">任务类型</div>
          <div class="extra">
            <a-button-group size="small">
              <a-button :type="type === '本月' ? 'primary' : 'default'" @click="type = '本月'">本月</a-button>
              <a-button :type="type === '全年' ? 'primary' : 'default'" @click="type = '全年'">全年</a-button>
            </a-button-group>
          </div>
        </div>
        <TaskRunning :detail="detail" />
      </div>
    </div>
    <div class="wrapper">
      <div class="card">
        <div class="header">
          <div class="title">平均时长分析</div>
          <div class="extra">
            <a-button-group size="small">
              <a-button :type="type === '本月' ? 'primary' : 'default'" @click="type = '本月'">本月</a-button>
              <a-button :type="type === '全年' ? 'primary' : 'default'" @click="type = '全年'">全年</a-button>
            </a-button-group>
          </div>
        </div>
        <TaskAnalysis :detail="detail" />
      </div>

      <div class="card">
        <div class="header">
          <div class="title">人员考核</div>
          <div class="extra">
            <a-button-group size="small">
              <a-button :type="type === '本月' ? 'primary' : 'default'" @click="type = '本月'">本月</a-button>
              <a-button :type="type === '全年' ? 'primary' : 'default'" @click="type = '全年'">全年</a-button>
            </a-button-group>
          </div>
        </div>

        <div style="padding: 2vh">
          <a-table bordered :data-source="Array.isArray(detail.dashboardWorkers) ? detail.dashboardWorkers : []"
            :scroll="{ y: '30vh' }" :pagination="false">
            <a-table-column title="序号" align="center">
              <template slot-scope="text, row, index">
                <div :title="text" class="text-overflow">{{ index + 1 }}</div>
              </template>
            </a-table-column>

            <a-table-column title="姓名" align="center">
              <template slot-scope="text">
                <div>{{ text.name }}</div>
              </template>
            </a-table-column>

            <a-table-column title="任务个数" align="center">
              <template slot-scope="text">
                {{ text.total }}
              </template>
            </a-table-column>
            <a-table-column title="完成个数" align="center">
              <template slot-scope="text">
                <div>{{ text.finished }}</div>
              </template>
            </a-table-column>
            <a-table-column title="预期个数" align="center">
              <template slot-scope="text">
                <div>{{ text.overdue }}</div>
              </template>
            </a-table-column>
            <a-table-column title="总工时(h)" align="center">
              <template slot-scope="text">
                <div>{{ text.workingHours }}</div>
              </template>
            </a-table-column>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskRunning from "./components/task-running.vue";
import TaskAnalysis from "./components/task-analysis.vue";
import FacilityError from "./components/facility-error.vue";
import FacilityErrorAnalysis from "./components/facility-error-analysis.vue";

import request from '@/api/request'

export default {
  components: {
    TaskRunning,
    TaskAnalysis,
    FacilityError,
    FacilityErrorAnalysis,
  },
  data() {
    return {

      statistic: {},

      // type: "本月",
      type: '全年',

      icon1: require("@/assets/task/icon1.png"),
      icon2: require("@/assets/task/icon2.png"),
      icon3: require("@/assets/task/icon3.png"),
      icon4: require("@/assets/task/icon4.png"),

      detail: {}
    };
  },

  mounted() {
    this.getData();

    this.getList();
  },

  watch: {
    type() {
      this.getList();
    }
  },

  methods: {
    getData() {
      request({
        url: '/model-analysis/inspectTask/queryDashboardTotal',
      }).then(res => {

        this.statistic = res || {}
      })
    },

    getList() {

      let condition = 'year'
      if (this.type === '本月') {
        condition = 'month'
      }

      const hide = this.$message.loading('加载中...')
      request({
        url: '/model-analysis/inspectTask/queryDashboardAnalysis/' + condition
      }).then(res => {
        this.detail = res ? Object.freeze(res) : {}
      }).finally(() => {
        hide();
      })

    }
  }

};
</script>

<style lang="less" scoped>
.topic {
  padding: 2vh 1vw;
}

.statistic {
  margin-bottom: 2vh;
  padding: 2vh;
  background-color: #000;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-around;

  .item {
    display: flex;
    align-items: center;
  }

  img {
    height: 4em;
    margin-right: 8px;
  }

  .title {
    font-weight: bold;
  }

  .value {
    font-weight: bold;
    color: var(--theme-color);
  }
}

.wrapper {
  display: flex;
  gap: 2vh;
  margin-bottom: 2vh;

  .card {
    background-color: #000;
    flex: 1;

    .header {
      background-color: var(--theme-color);
      color: #fff;
      display: flex;
      align-items: center;
      padding: 1vh 1vw;

      .title {
        font-weight: bold;
      }

      .extra {
        margin-left: auto;
      }
    }
  }
}
</style>