<template>
  <div id="facility-error" style="height: 35vh"></div>
</template>

<script>
import * as echarts from "echarts/core";
import { TooltipComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([TooltipComponent, PieChart, CanvasRenderer, LabelLayout]);

export default {
  props: {
    detail: {
      type: Object,
    },
  },

  computed: {
    sourceAnalysis() {
      return Array.isArray(this.detail.sourceAnalysis)
        ? this.detail.sourceAnalysis
        : [];
    },
  },

  watch: {
    sourceAnalysis: {
      handler() {
        this.setChart();
      },
      deep: true,
    },
  },
  mounted() {
    var chartDom = document.getElementById("facility-error");
    this.chart = echarts.init(chartDom);
    this.setChart();
  },

  beforeDestroy() {
    this.chart?.dispose();
  },

  methods: {
    setChart() {
      if (this.sourceAnalysis.length === 0) {
        return;
      }

      var option = {
        color: ["#FFA700", "#0AD8F3"],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["50%", "65%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              formatter: ["{title|{b}}", "{percentage|{d}%}"].join("\n"),
              rich: {
                title: {
                  lineHeight: 24,
                },
                percentage: {
                  fontSize: 16,
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              show: true,
            },
            data: this.sourceAnalysis.map((item) => {
              return {
                name: item.key,
                value: item.value,
              };
            }),
          },
        ],
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>