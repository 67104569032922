<template>
  <div id="task-analysis" style="height: 35vh"></div>
</template>

<script>
import * as echarts from "echarts/core";
import { GridComponent, LegendComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([GridComponent, BarChart, CanvasRenderer, LegendComponent]);

export default {
  props: {
    detail: {
      type: Object,
    },
  },

  computed: {
    // 巡检任务
    avgPatrolAnalysis() {
      return Array.isArray(this.detail.avgPatrolAnalysis)
        ? this.detail.avgPatrolAnalysis
        : [];
    },
    avgMaintenanceAnalysis() {
      return Array.isArray(this.detail.avgMaintenanceAnalysis)
        ? this.detail.avgMaintenanceAnalysis
        : [];
    },

    avgRepairAnalysis() {
      return Array.isArray(this.detail.avgRepairAnalysis)
        ? this.detail.avgRepairAnalysis
        : [];
    },
  },

  watch: {
    avgPatrolAnalysis: {
      handler() {
        this.setChart();
      },
      deep: true,
    },
  },

  mounted() {
    var chartDom = document.getElementById("task-analysis");
    this.chart = echarts.init(chartDom);
    this.setChart();
  },

  beforeDestroy() {
    this.chart?.dispose();
  },

  methods: {
    setChart() {
      if (this.avgPatrolAnalysis.length === 0) {
        return;
      }

      const series = Object.keys(this.avgPatrolAnalysis).map((key) => {
        return {
          data: [
            this.avgPatrolAnalysis[key] ? this.avgPatrolAnalysis[key].value : 0,
            this.avgMaintenanceAnalysis[key]
              ? this.avgMaintenanceAnalysis[key].value
              : 0,
            this.avgRepairAnalysis[key] ? this.avgRepairAnalysis[key].value : 0,
          ],
          type: "bar",
          stack: "value",
          barWidth: 15,
          name: "接收时长",
        };
      });

      var option = {
        color: ["#4ECB73", "#0AD8F3"],
        grid: {
          right: "5%",
          bottom: "15%",
        },
        legend: { textStyle: { color: "#fff" }, right: "12px", top: "12px" },
        yAxis: {
          type: "category",
          data: ["巡检任务", "养护任务", "维修任务"],
        },
        xAxis: {
          type: "value",
        },
        series,
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>